import NewHeader from "../components/NewHeader";
import SmartoothAppCSS from "./SmartoothApp.module.css";
import Footer from "../components/Footer";
import Loader from '../components/Loader';
import React,{useState,useEffect} from 'react';

function SmartoothApp() {
  const imagestyle = {
      height: "180px",
      width: "180px",
  };
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, []);
  /*
  return (
    <div className={SmartoothAppCSS.content}>
      <NewHeader />
      <br />
      <br />
      <div className={SmartoothAppCSS.row}>
        <div className={SmartoothAppCSS.wrap}>
          <a href="https://drive.google.com/file/d/1VD9-TsiYJ7cXk6WWKrgbrlFPpB-6RFxT/view?usp=sharing" download>
            <img className={SmartoothAppCSS.downloadBtn} src="/img/download_btn.png" alt="Smartooth premium APK" style={imagestyle}/><br />
            Smartooth Android APK Download!!<br/>Android10(sdk29)<br/>
          </a>
        </div>
      </div>
      <br/>
      <br/>
      <br/>
      <Footer />
    </div>
  );
  */
  return (
    <div className={SmartoothAppCSS.content}>
      <NewHeader />
      <br />
      <br />
        <div className={SmartoothAppCSS.userwrap}>
            <div className={SmartoothAppCSS.userimage}>
                <img src="https://www.smartooth.co/img/download_top.png" alt="Smartooth" width="800px" />
            </div>
            <div className={SmartoothAppCSS.usertext}>
                <span className={SmartoothAppCSS.usertextp1}>구강 진단장비 & 데이터 서비스</span><br />
                <span className={SmartoothAppCSS.usertextp2}>구강관리 토탈 솔루션</span><br />
                <span className={SmartoothAppCSS.usertextp3}>SMARTOOTH</span><br />    
            </div>
            <div className={SmartoothAppCSS.usertext}>
                <img src="https://www.smartooth.co/img/download_top_c.png" alt="Smartooth" width="300px" />
            </div>
        </div>
        <div className={SmartoothAppCSS.userparent1}>
            <div className={SmartoothAppCSS.userwrap1}>
                <div className={SmartoothAppCSS.userimage1}>
                    <a href="/download/SmartoothPremium_DentService_20240826_28.apk" type="application/vnd.android.package-achive" download><img src="https://www.smartooth.co/img/download_dent.png" alt="Smartooth" width="230px" /></a>
                </div>
                <div className={SmartoothAppCSS.usertext1}>
                    <p>Version 1.1.28</p>
                </div>
            </div>
            <div className={SmartoothAppCSS.userwrap1}>
                <div className={SmartoothAppCSS.userimage1}>
                    <a href="/download/smartooth_phc_service_20240909_3.apk" type="application/vnd.android.package-achive" download><img src="https://www.smartooth.co/img/download_healthcare.png" alt="Smartooth" width="230px" /></a>
                </div>
                <div className={SmartoothAppCSS.usertext1}>
                    <p>Version 1.0.3</p>
                </div>
            </div>
            <div className={SmartoothAppCSS.userwrap1}>
                <div className={SmartoothAppCSS.userimage1}>
                    <a href="/download/smartooth_school_service_20240711_1.2.8.apk" type="application/vnd.android.package-achive" download><img src="https://www.smartooth.co/img/download_kinder.png" alt="Smartooth" width="230px" /></a>
                </div>
                <div className={SmartoothAppCSS.usertext1}>
                    <p>Version 1.2.8</p>
                </div>
            </div>
        </div>
        <div className={SmartoothAppCSS.userparent1}>
            <div className={SmartoothAppCSS.userwrap1}>
                <div className={SmartoothAppCSS.userimage1}>
                    <a href="/download/smartooth_dpapp_20240719_1.apk" type="application/vnd.android.package-achive" download><img src="https://www.smartooth.co/img/download_dpapp.png" alt="Smartooth" width="230px" /></a>
                </div>
                <div className={SmartoothAppCSS.usertext1}>
                    <p>Version 1.0.1</p>
                </div>
            </div>
            <div className={SmartoothAppCSS.userwrap1}>
                <div className={SmartoothAppCSS.userimage1}>
                    <a href="/download/smartooth_20240829_4.apk" type="application/vnd.android.package-achive" download><img src="https://www.smartooth.co/img/download_general.png" alt="Smartooth" width="230px" /></a>
                </div>
                <div className={SmartoothAppCSS.usertext1}>
                    <p>Version 1.0.4</p>
                </div>
            </div>
        </div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      <Footer />
    </div>
  );
}
export default SmartoothApp;
